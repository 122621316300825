import React, { useEffect, useState } from 'react';

import Driver from '../../../components/Driver/Driver';
import Constructor from '../../../components/Constructor/Constructor';
import SkeletonQuestion from '../../../components/Skeleton/SkeletonQuestion';

import EventDriverSettings from './EventDriverSettings/EventDriverSettings';
import EventConstructorSettings from './EventConstructorSettings/EventConstructorSettings';

import Select from '../../../atoms/Select/Select';
import Button from '../../../atoms/Button/Button';
import Modal from '../../../atoms/Modal/Modal';

import SettingsIcon from '@mui/icons-material/Settings';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArticleIcon from '@mui/icons-material/Article';

import './StatsTab.css';

function StatsTab({user, eventId}) {    
    const [relatedEvents, setRelatedEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();

    const [driverSettingsConfig, setDriverSettingsConfig] = useState({show: false});  
    const [constructorSettingsConfig, setConstructorSettingsConfig] = useState({show: false}); 

    const [drivers, setDrivers] = useState([]);
    const [constructors, setConstructors] = useState([]);

	//update relatedEvents hook
    useEffect(() => {
		console.log('useEffect fetchRelatedEvents', eventId)
		if(relatedEvents.length === 0) {
			fetchRelatedEvents();
		}
    }, [eventId]);

	//get stats data hook
    useEffect(() => {
		if(selectedEvent !== undefined) {
			fetchEventGridData();
		}
    }, [selectedEvent]);


	const fetchRelatedEvents = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				eventId: eventId
			})
		};
	
		fetch(apiUrl + `/app/ref/relatedEvents`, requestOptions)
			.then(response => response.json())
			.then(data => {
				data.forEach(d => d.datetime = new Date(d.date))
				let orderedEvents = data.sort((a, b) => b.datetime - a.datetime);
				setRelatedEvents(orderedEvents);
				setSelectedEvent(orderedEvents[0]);
			})
			.catch((err) => {
				console.log(err);
			});
	}

    const fetchEventGridData = () => {
        fetchEventDriversData();
        fetchEventConstructorsData();
    }

    const fetchEventDriversData = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				eventId: selectedEvent.eventId
			})
		};
		
		setDrivers();	//Setting undefined for the skeleton loader
		
		fetch(apiUrl + `/app/ref/eventDrivers`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setDrivers(data);
			})
			.catch((err) => {
				setDrivers([]);		// resetting default to remove the skeleton
				console.log(err);
			});
    }
    const fetchEventConstructorsData = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				eventId: selectedEvent.eventId
			})
		};

		setConstructors();	//Setting undefined for the skeleton loader
	
		fetch(apiUrl + `/app/ref/eventConstructors`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setConstructors(data);
			})
			.catch((err) => {
				setConstructors([]);		// resetting default to remove the skeleton
				console.log(err);
			});
    }
  
  
  
    const updateSessionData = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
			eventId: selectedEvent.eventId,
			sessionKey: selectedEvent.sessionKey
			})
		};
	
		fetch(apiUrl + `/import/updateSessionData`, requestOptions)
			.then(() => {          
				fetchEventGridData();
			})
			.catch((err) => {
				console.log(err);
			});
    }
    
    
    const openDriverSettings = () => {
      console.log('openDriverSettings')
        setDriverSettingsConfig({
            show: true, 
            eventId: selectedEvent.eventId,
            type: selectedEvent.type,
            name: selectedEvent.name
        });
    }
    const hideDriverSettings = () => {
      	setDriverSettingsConfig({show: false, eventId: 0});
        fetchEventGridData();
    } 
    
    
    const openConstructorSettings = () => {
		console.log('openConstructorSettings')
        setConstructorSettingsConfig({
            show: true, 
            eventId: selectedEvent.eventId,
            type: selectedEvent.type,
            name: selectedEvent.name
        });
    }
    const hideConstructorSettings = () => {
      	setConstructorSettingsConfig({show: false, eventId: 0});
        fetchEventGridData();
    }  
    

    
    const getDriverTime = (bestLapTime, gap) => {
      if(gap === 0) {
        return formatDuration(bestLapTime)
      } else {
        return `+${formatDuration(gap)}`
      }
    }

    const raceEvent = () => {  
		const allowedTypes = ['Grand Prix', 'Sprint'];
		if (allowedTypes.includes(selectedEvent?.type)) {
			return true;
		} else {	
			return false;
		}
	}

    const showLaps = () => {  
		const allowedTypes = ['Grand Prix', 'Sprint', 'Practice 1', 'Practice 2', 'Practice 3'];
		if (allowedTypes.includes(selectedEvent?.type)) {
			return true;
		} else {	
			return false;
		}
	}
	
	const formatPlace = (startPosition, currentPosition, status) => {
		const posChange = startPosition - currentPosition;
		let formattedPosChange;

		if (posChange > 0) { 
			formattedPosChange = `+${posChange}`; } 
		else if (posChange < 0) { 
			formattedPosChange = `${posChange}`; 
		} else { 
			formattedPosChange = '—'; 
		}

		if(status === null) {
			return (
			<div className='flex gap-05'>
				<span>P{currentPosition}</span>
				<span style={posChange===0 ? {color: 'gray'}: (posChange>0 ? {color: 'green'} : {color: 'red'})}>{formattedPosChange}</span>
			</div>
			)
		} else {
			return (<span>{status}</span>);
		}
	}

    const formatDuration = (duration) => {        
        let minutes = Math.floor(duration / 60);
        let seconds = (duration % 60).toFixed(3); // Keep the thousandth place

        if (minutes === 0) {
            return `${seconds}s`;

        } else {
            let [real, decimal] = seconds.split('.');
            real = real.padStart(2, '0'); // Ensure the real part is two digits
            seconds = `${real}.${decimal}`;
            return `${minutes}:${seconds}s`;
        }
    }

    return (
    <div className='event-details'>
        <EventDriverSettings config={driverSettingsConfig} onHide={hideDriverSettings}></EventDriverSettings>
        <EventConstructorSettings config={constructorSettingsConfig} onHide={hideConstructorSettings}></EventConstructorSettings>

        <div className='flex-grow-1 flex justify-content-between'>
          <Select 
                options={relatedEvents.map(e => e.type)}
                value={selectedEvent?.type}
                clickFunction={(_, i) => setSelectedEvent(relatedEvents[i])}
              >
          </Select>
          {user.isAdmin && (
          <Button variant='icon' onClick={updateSessionData}>
              <CloudDownloadIcon fontSize="small" />
          </Button>
          )}
        </div>
		<div className='dark-card note'>
			<label>Preview</label>
			Data not available for all events.
		</div>

		{drivers === undefined ? (<SkeletonQuestion></SkeletonQuestion>) : (<>
        <div className="dark-card">
            <div className='flex justify-content-between'>
                <h2>Drivers</h2>

                {user.isAdmin && (
                <Button variant='icon' onClick={openDriverSettings}><SettingsIcon fontSize="small" /></Button>
                )}
            </div>

            <div className='overflow-auto padding-0'>
              <table className="table stats-table">
				<colgroup>
					<col />
					<col col-width="short-number" />
					{raceEvent() && (<col col-width='long-number' />)}
					{!raceEvent() && (<col col-width='long-number' />)}
					{showLaps() && (<col col-width='short-number' />)}
				</colgroup>
                <thead>
                    <tr>
                        <th className='subject-cell'></th>
                        <th>Pos.</th>                       
						
                        {raceEvent() && (<th className='text-align-left'>Gap</th>)}
                        {!raceEvent() && (<th className='text-align-left'>Time</th>)}
                        {showLaps() && (<th className='text-align-left'>Laps</th>)}
                    </tr>
                </thead>
                {drivers.length > 0 && (
                <tbody>
                    {drivers.map(d => (
                    <tr>

                        <td className='subject-cell'>
							<div>
                            	<Driver driver={d} eventId={eventId}></Driver>
							</div>
                        </td>

                        <td className='stat-cell'>{formatPlace(d.startPosition, d.currentPosition, d.status)}</td>

                        {raceEvent() && (<td className='stat-cell'>{d.gapToLeader}</td>)}
                        {!raceEvent() && (<td className='stat-cell'>{getDriverTime(d.bestLapTime, d.bestLapTimeGap)}</td>)}

						{showLaps() && (<td className='stat-cell'>{d.lapsCompleted}</td>)}
                    </tr>
                    ))}
                </tbody>
                )}
            </table>
          </div>
        </div>
		</>)}


		{constructors === undefined ? (<SkeletonQuestion></SkeletonQuestion>) : (<>
        <div className="dark-card">
			<div className='flex justify-content-between'>
				<h2>Constructors</h2>

					{user.isAdmin && (
					<Button variant='icon' onClick={openConstructorSettings}><SettingsIcon fontSize="small" /></Button>
					)}
			</div>

			<div className='overflow-auto padding-0'>
				<table className="table stats-table">
					<colgroup>
						<col />
						{raceEvent() && (<col col-width='short-number' />)}
						{raceEvent() && (<col col-width='short-number' />)}
					</colgroup>
					<thead>
						<tr>
							<th className='subject-cell'></th>
							{raceEvent() && (<th className='text-align-left'>Pit Stops</th>)}
							{raceEvent() && (<th className='text-align-left'>Best</th>)}
						</tr>
					</thead>
					{constructors.length > 0 && (
					<tbody>
						{constructors.map(c => (
						<tr>
							<td className='subject-cell'>
								<div>
									<Constructor constructor={c} eventId={eventId}></Constructor>
								</div>
							</td>

							{raceEvent() && (<td className='stat-cell'>{c.pitStopCount}</td>)}
							{raceEvent() && (<td className='stat-cell'>{c.bestPitStop !== null ? `${c.bestPitStop?.toFixed(1)}s` : ''}</td>)}
						</tr>
						))}
					</tbody>
					)}
				</table>
			</div>
        </div>
		</>)}
    </div>
    );
}

export default StatsTab;