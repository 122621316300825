import React, { useReducer, useEffect, useState } from 'react';

import Loader from '../../../components/Loader/Loader';
import Tooltip from '../../../components/Tooltip/Tooltip';
import SkeletonQuestion from '../../../components/Skeleton/SkeletonQuestion';

import PicksSettings from './PicksSettings/PicksSettings';
import AddQuestion from './AddQuestion/AddQuestion';
import QuestionSettings from './QuestionSettings/QuestionSettings';
import DriverPicks from './DriverPicks/DriverPicks';
import ConstructorPicks from './ConstructorPicks/ConstructorPicks';
import BonusRadioPicks from './BonusRadioPicks/BonusRadioPicks';

import Button from '../../../atoms/Button/Button';

import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import './PicksTab.css';

function reducer(state, action) {
  console.log(`reducer (${action.type}, ${action?.payload})`);   
  
  let newState = structuredClone(state);
  try {
		switch(action.type) {
			case 'initialize':
				return action.payload;


			case 'set_submission_validation':
				newState.submission.validationMessage = action.payload;
				
				return newState;

			case 'set_question_validation':
				let isSubmissionInvalid = false;
				newState.form.section.forEach((section, sIndex) => {
					section.question.forEach((question, qIndex) => {
						if (question.questionId === action.payload.questionId) {
							newState.form.section[sIndex].question[qIndex].validationMessage = action.payload.message;
						}
						if (question.validationMessage !== undefined) {
							console.log('question validation issue exists', question.validationMessage)
							isSubmissionInvalid = true;
						}
					});
				});
				if(isSubmissionInvalid === false) {
					newState.submission.validationMessage = undefined;
				}
			  	return newState;

          default:
              console.log(`Action ${action.type} not known.`);
              return newState;
      }
  }
  catch (error) {
      console.error(`An error occurred (${action.type}):`, error);
      return newState;
  }
}


function PicksTab({user, eventId, refreshEventInfo}) {
    const [loader, setLoader] = useState({show: false, text: ''});

    const [picksSettingsConfig, setPicksSettingsConfig] = useState({show: false});
    const [tooltipConfig, setTooltipConfig] = useState({show: false});
    const [addQuestionModal, setAddQuestionModal] = useState({show: false});
    const [questionSettingsModal, setQuestionSettingsModal] = useState({show: false});
    const [submitMessage, setSubmitMessage] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);

    const [picks, dispatch] = useReducer(reducer, []);


    useEffect(() => {
        console.log('PicksTab', eventId);
        if(user.userId !== undefined && user.userId !== null && eventId !== undefined && eventId !== null) {
          fetchPicks();
        }
    }, [user, eventId]);

    useEffect(() => {
        console.log('useEffect: picks');
		determineSubmitState();
    }, [picks]);

    const fetchPicks = (submissionId) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              eventId: eventId,
              userId: user.userId,
              submissionId: submissionId
            })
        };
    
		// setLoader({show: true, text: 'Loading Picks...'});
		dispatch({type: 'initialize', payload: []});
		fetch(apiUrl + `/app/picks`, requestOptions)
			.then(response => response.json())
			.then(data => {
				if(data.form !== undefined) {
					data.form.section.sort((a, b) => a.order - b.order);
				}

				dispatch({type: 'initialize', payload: data});

				if(data.form?.formId === undefined) {
					createForm();
				} else if(data.submission?.submissionId === undefined) {
					createSubmission();
				}
			})
			.catch((err) => {
				console.log(err);
			});
    }

    
    const createForm = () => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              eventId: eventId
          })
      };

      setLoader({show: true, text: 'Creating Form...'});
      fetch(apiUrl + "/app/form/create", requestOptions)
        .then(response => response.json())
        .then(data => {
          setLoader({show: false});
          createSubmission();
        })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }
      
    const createSubmission = () => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: eventId, 
            userId: user.userId 
          })
      };

      setLoader({show: true, text: 'Creating Submission...'});
      fetch(apiUrl + "/app/submission/create", requestOptions)
        .then(response => response.json())
        .then(data => {
          setLoader({show: false});
          fetchPicks();
        })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }

    // Add Question Modal
    const openAddQuestionModal = (afterSection) => {
		setAddQuestionModal({show: true, userId: user.userId, formId: picks.form?.formId, afterSection: afterSection});
	}
    const closeAddQuestionModal = (action) => {
		console.log('closeAddQuestionModal', action);
		if(action === 'Reload') {
			fetchPicks();
		}
		setAddQuestionModal({show: false});
    }

    // Question Settings Modal
    const openQuestionSettings = (e, questionId) => {
      	setQuestionSettingsModal({show: true, questionId: questionId, eventId: eventId});
    }
    const closeQuestionSettings = (action) => {
		console.log(`closeQuestionSettings: ${action}`);
		if(action === 'Reload') {
			fetchPicks();
		}
		setQuestionSettingsModal({show: false});
    }


    // Picks Settings
    const openPicksSettings = () => {
		console.log('openPicksSettings');
		
		if(picks.form !== undefined && picks.submission !== undefined) {
			let form = {
				formId: picks.form.formId,
				state: picks.form.state,
				memeUrl: picks.form.memeUrl,
				submissionDeadline: picks.form.submissionDeadline
			}

			let submission = {
				submissionId: picks.submission.submissionId,
				state: picks.submission.state
			}

			setPicksSettingsConfig({
				show: true, 
				form: form,
				submission: submission
			});
		}
    }
    const hidePicksSettings = (action, value) => {
		setPicksSettingsConfig({show: false});
		if(action === 'Reload') {
			fetchPicks(value);		// reload picks
			refreshEventInfo();		// to get the tab styles updated if applicable
			determineSubmitState();
		}
    }


    // Tooltip
    const showTooltip = (e, header, body) => {
      setTooltipConfig({show: true, header: header, body: body});
    }
    const closeTooltip = () => {
      setTooltipConfig({show: false, header: '', body: ''});
    }



	const formattedLocalSubmissionDeadline = (dateString) => {
		const date = new Date(dateString);
		return `${date.toLocaleString([], {weekday: 'short' })}, ${date.toLocaleString([], {month: 'short', day: 'numeric' })}, ${date.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`;
	}
	const formattedSubmissionDate = (dateString) => {
		const today = new Date();
		const date = new Date(dateString);
		const isToday = date.toDateString() === today.toDateString();

		if (isToday) {
			const options = { hour: '2-digit', minute: '2-digit' };
			return `Picks submitted today at ${date.toLocaleTimeString(undefined, options)}.`;
		} else {
			const options = { month: 'short', day: 'numeric' };
			return `Picks submitted ${date.toLocaleDateString(undefined, options)}.`;
		}
	}

	const determineSubmitState = () => {
		let [submit, message] = testCanSubmit();
		setCanSubmit(submit);
		setSubmitMessage(message);
	}

	// Common test function
	const testCanSubmit = () => {
		const currentTime = new Date();
		const submissionDeadlineDate = new Date(picks.form?.submissionDeadline);
		
		let message;
		let submit;
		if(currentTime > submissionDeadlineDate) {
			submit = false;
			message = 'Submission period has ended.';

		} else if(picks.submission?.state === 'Submitted') {
			if(picks.form?.allowResubmissions) {
				submit = true;
				message = `${formattedSubmissionDate(picks.submission?.tsUpdated)} For this event, resubmissions are allowed.`;
			} else {
				submit = false;
				message = `${formattedSubmissionDate(picks.submission?.tsUpdated)} Good luck!`;
			}

		} else {
			submit = true;
			if(picks.form?.allowResubmissions) {
				message = 'For this event, resubmissions are allowed.';
			} else {
				message = 'After submission, changes are not allowed.';
			}
		}

		return [submit, message]

	}

	const validateAndSubmitPicks = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
			  submissionId: picks.submission.submissionId
			})
		};

		fetch(apiUrl + "/app/picks/validate", requestOptions)
		  .then(response => response.json())
		  .then(data => {
			let invalidPicks = data.filter(v => v.isValid === false);

			if(invalidPicks.length > 0) {
				console.log('invalid picks', invalidPicks.length);
				invalidPicks.map(v => dispatch({type: 'set_question_validation', payload: v}));
				dispatch({type: 'set_submission_validation', payload: 'Errors exist. Please fix in order to submit.'});
			} else {
				submitPicks();
			}
		  })
		  .catch((err) => {
			console.log(err);
		  });
	}

	const submitPicks = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
			  submissionId: picks.submission.submissionId
			})
		};
  
		setLoader({show: true, text: 'Submitting picks...'});
		fetch(apiUrl + "/app/picks/submit", requestOptions)
		  .then(response => response.json())
		  .then(data => {
			setLoader({show: false});
			refreshEventInfo();
			fetchPicks();
		  })
		  .catch((err) => {
			console.log(err);
			setLoader({show: false});
		  });
	}

	const handlePickChange = (questionId) => {
		dispatch({type: 'set_question_validation', payload: {questionId: questionId, isValid: true}});
	}
    

    return (
    <div className='event-details'>
        <Loader config={loader}></Loader>

        <PicksSettings config={picksSettingsConfig} onHide={hidePicksSettings}></PicksSettings>
        <Tooltip config={tooltipConfig} onHide={closeTooltip}></Tooltip>
        <AddQuestion config={addQuestionModal} onHide={closeAddQuestionModal}></AddQuestion>
        <QuestionSettings config={questionSettingsModal} onHide={closeQuestionSettings}></QuestionSettings>

		<div className='dark-card note'>
			<div className='grid grid-template-x w-100'>
				{picks.form?.state === 'Draft' && (
					<div className='grid grid-area-x align-self-center'>{`Submissions Open Soon!`}</div>
				)}
				{picks.form?.state === 'Live' && (
					<div className='grid grid-area-x align-self-center'>{`Submissions open until ${formattedLocalSubmissionDeadline(picks.form?.submissionDeadline)}`}</div>
				)}
				{picks.form?.state === 'Complete' && (
					<div className='grid grid-area-x align-self-center'>{`Submissions period has ended.`}</div>
				)}
				
				{user.isAdmin && (
					<Button className='grid grid-area-x justify-self-end' variant='icon' onClick={openPicksSettings}>
						<SettingsIcon fontSize="small" />
					</Button>
				)}
			</div>
			{picks.submission?.user?.userId !== undefined && user.userId !== picks.submission?.user?.userId && (
				<div><b>Impersonating user {picks.submission?.user?.displayName}</b></div>
			)}
		</div>

		{(picks.form?.state !== 'Draft' || user.isAdmin) && (<>
			{user.isAdmin && (
			<div className='flex justify-content-center w-100'>
				<div className="adder" onClick={() => openAddQuestionModal(null)}>
					<div className="adder-line">
						<div className="adder-label"><AddIcon fontSize="small" />Add Question</div>
					</div>
				</div>
			</div>
			)}

			
			{picks.length === 0 ? (<SkeletonQuestion></SkeletonQuestion>) : (<> 
			{picks.form?.section.length > 0 && picks.form.section.map(s => (<>
			<div className="dark-card section margin-inline-auto scrollable" key={`s${s.sectionId}`} id={s.sectionId}>

				{ s.title!==undefined && (
				<div className="section-header">
					<h2>{s.title}</h2>

					{s.description?.length > 0 && (
					<Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
					)}
				</div>
				)}
				
				{ s.question.length > 0 && s.question[0].questionId !== undefined && (
					s.question.map(q => (<>
					<div className='question-header'>
						<div className="flex justify-content-between align-items-center">
							<h3>{q.text}</h3>
							<div className='flex'>
								{q.description?.length > 0 && (
								<Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
								)}

							
								{user.isAdmin && (
								<Button variant='icon' onClick={(e) => openQuestionSettings(e, q.questionId)}>
									<SettingsIcon fontSize="small" />
								</Button>
								)}
							</div>
						</div>
						{q.validationMessage !== undefined && q.validationMessage?.length >0 && (
							<div className='validation-message'>{q.validationMessage}</div>
						)}
					</div>

					{q.type === 'Driver Picks' && (
					<DriverPicks user={user} questionId={q.questionId} submissionId={picks.submission?.submissionId} eventId={eventId} submitTest={testCanSubmit} onPickChange={handlePickChange}></DriverPicks>
					)}


					{q.type === 'Constructor Picks' && (
					<ConstructorPicks user={user} questionId={q.questionId} submissionId={picks.submission?.submissionId} eventId={eventId} submitTest={testCanSubmit} onPickChange={handlePickChange}></ConstructorPicks>
					)}

					{q.type === 'Bonus Radio' && (
					<BonusRadioPicks user={user} questionId={q.questionId} submissionId={picks.submission?.submissionId} submitTest={testCanSubmit} onPickChange={handlePickChange}></BonusRadioPicks>
					)}
					</>))
				)}
			</div>
			

			{user.isAdmin && (
			<div className="adder" onClick={() => openAddQuestionModal(s.sectionId)}>
				<div className="adder-line">
					<div className="adder-label"><AddIcon fontSize="small" />Add Question</div>
				</div>
			</div>
			)}
			</>))}
			</>)}
			
			<div className='submit-sheet'>
				<div className='dark-card'>
					<div className='flex justify-content-center align-items-center gap-1 w-100'>
						<p>{submitMessage}
							{user.isAdmin === true && canSubmit === false && (
								<Button variant='link' onClick={submitPicks}>*Submit*</Button>
							)}
						</p>
						<Button variant='primary' onClick={validateAndSubmitPicks} disabled={!canSubmit}>Submit <NavigateNextIcon /></Button>
					</div>
					{picks.submission?.validationMessage !== undefined && (
						<div className='validation-message'>{picks.submission?.validationMessage}</div>
					)}
				</div>
			</div>
		</>)}
    </div>
    );
}

export default PicksTab;