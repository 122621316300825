import React, { useState, useEffect, useReducer } from 'react';

import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import Input from '../../atoms/Input/Input';
import Select from '../../atoms/Select/Select';

import AreYouSureModal from '../AreYouSureModal/AreYouSureModal';
import PictureChooser from '../PictureChooser/PictureChooser';


import SaveIcon from '@mui/icons-material/Save';

import './EventSettings.css';

function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload})`);   
    
    let newState = structuredClone(state);

    try {  
        switch(action.type) {
            case 'initialize':
                newState = action.payload;
                return newState;
  
            case 'reset':
                newState = {};
                return newState;
  
            case 'add':
                newState = {
                    action: 'add',
                    name: '',
                    laps: 0,
                    round: 0,
                    season: new Date().getFullYear(),
                    type: 'Grand Prix',
                    accentColor: '#333333',
                    ...action.payload
                };
                return newState;

            case 'update':
                if(action.payload.property === 'Name') {
                    newState.name = action.payload.value;
                    
                } else if(action.payload.property === 'Date') {
                    newState.date = action.payload.value;
                    
                } else if(action.payload.property === 'Round') {
                    newState.round = action.payload.value;
                    
                } else if(action.payload.property === 'Season') {
                    newState.season = action.payload.value;

                } else if(action.payload.property === 'Laps') {
                    newState.laps = action.payload.value;

                } else if(action.payload.property === 'Type') {
                    newState.type = action.payload.value;
                    
                } else if(action.payload.property === 'Circuit') {
                    newState.circuitId = action.payload.value.circuitId;
                    newState.circuitName = action.payload.value.name;
                    newState.locality = action.payload.value.locality;
                    newState.country = action.payload.value.country;
                    newState.lat = action.payload.value.lat;
                    newState.long = action.payload.value.long;
                    newState.lat = action.payload.value.lat;
                    newState.wikiUrl = action.payload.value.wikiUrl;
                    newState.trackUrl = action.payload.value.trackUrl;
                    newState.trackSvgUrl = action.payload.value.trackSvgUrl;
                    newState.flagUrl = action.payload.value.flagUrl;
                    
                } else if(action.payload.property === 'Poster URL') {
                    newState.posterUrl = action.payload.value;

                } else if(action.payload.property === 'Accent Color') {
                    newState.accentColor = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}

function EventSettings({config, onHide}) {
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});
    const [deleteModalConfig, setDeleteModalConfig] = useState({show: false});
    const [event, dispatch] = useReducer(reducer, {});
    const [dbData, setDbData] = useState();
    const [hasSaved, setHasSaved] = useState(false);
    const [circuits, setCircuits] = useState([]);
    const [eventTypes, setEventTypes] = useState([
        'Grand Prix',
        'Qualifying',
        'Sprint',
        'Sprint Qualifying',
        'Practice 1',
        'Practice 2',
        'Practice 3'
    ]);
    
    useEffect(() => {
        if(config.show) {
            fetchEvent(config.eventId);
            fetchCircuits();
            console.log('config', config);
        }

    }, [config]);
    

    const fetchEvent = (eventId) => {    
        if(eventId === undefined) {
            dispatch({type: 'add', payload: {season: config.season, type: 'Grand Prix'}});

        } else {
            let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                eventId: eventId
              })
            };
        
            fetch(apiUrl + `/app/ref/event`, requestOptions)
              .then(response => response.json())
              .then(data => {
                  dispatch({type: 'initialize', payload: data});
                  setDbData(data);
        
              })
              .catch((err) => {
                  console.log(err);
              });
        }
    }

    const fetchCircuits = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        fetch(apiUrl + `/app/ref/circuits`, requestOptions)
        .then(response => response.json())
        .then(data => {
            const ordered = data.sort((a, b) => a.name.localeCompare(b.name));
            setCircuits(ordered);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const handleEventChange = (property, value) => {
        console.log(`handleEventChange [${property}] => ${value}`);
        
        dispatch({type: 'update', payload: {property: property, value: value}});
    }
    
    const save = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                events: [event]
            })
        };

        fetch(apiUrl + "/app/ref/events/save", requestOptions)
			.then(response => {
				if(response.status === 200) {return response.json()}
			})
			.then(data => {
				if(event.action === 'add') {
					fetchEvent(data[0].eventId);
				} else {
					fetchEvent(config.eventId);
				}
				setHasSaved(true);
			})
			.catch((err) => {
				console.log(err);
			});
    }


    const openDeleteModal = () => {
        setDeleteModalConfig({show: true});
    }
    const closeDeleteModal = (action) => {
        setDeleteModalConfig({show: false});

        if(action === 'Confirm') {
            deleteEvent();
        }
    }

    const deleteEvent = () => {
        if(event.eventId === undefined) {
            handleCloseEvent();
        } else {
            let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    eventId: event.eventId
                })
            };
            
            fetch(apiUrl + `/app/ref/event/delete`, requestOptions)
            .then(response => response.json())
            .then(() => {    
                handleCloseEvent('Reload');
            })
            .catch((err) => console.log(err));
        }
    }

    // const formatDateString = (datestring) => {
    //     let date = new Date(datestring);

    //     let localDatetimeString = `${date.toLocaleString([], {weekday: 'short' })}, ${date.toLocaleString([], {month: 'short', day: 'numeric' })}, ${date.toLocaleString([], {hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}`

    //     return localDatetimeString;
    // }

    const openPictureChooser = (property) => {
        setPictureChooserConfig({show: true, property});
    }

    const closePictureChooser = (url) => {
        console.log(`url (${url.length})`, url);
        
        if(url.length > 0) {
            let property = pictureChooserConfig.property;
            handleEventChange(property, url);
        }
        
        setPictureChooserConfig({show: false});
    }

    const handleCloseEvent = (action) => {
        if(hasSaved) {action = 'Reload';}

        onHide(action);
        setHasSaved(false);
    }

    const isDirty = () => {
        if (JSON.stringify(event) !== JSON.stringify(dbData)) { 
            return true; 
        } else {
            return false;
        }
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>
            <AreYouSureModal config={deleteModalConfig} onHide={closeDeleteModal}></AreYouSureModal>

            <div>
                <div className="modal-header">
                    <h2 className="margin-0">Event Settings</h2>
                    <Button variant="warn" onClick={openDeleteModal}>
                        Delete<SaveIcon fontSize='small' />
                    </Button>
                    <Button variant="primary" onClick={save} disabled={!isDirty()}>
                        {event.eventId === undefined ? 'Create' : 'Save'} <SaveIcon fontSize='small' />
                    </Button>
                </div>
            </div>
            
            <div className='flex flex-column gap-1 overflow-auto'>
                <div className="dark-card note">{`{ eventId: ${event.eventId} }`}</div>

                <div className='col-2 flex-shrink-0'>
                    <Input name="Season" 
                        type="text" 
                        value={event.season}
                        onChange={(e) => handleEventChange(e.target.name, e.target.value)} />
                        
                    <Input name="Round" 
                        type="text" 
                        value={event.round}
                        onChange={(e) => handleEventChange(e.target.name, e.target.value)} />
                </div>
                
                <Input name="Name" 
                    type="text" 
                    value={event.name}
                    onChange={(e) => handleEventChange(e.target.name, e.target.value)} />
                    
                <Select 
                    name="Type" 
                    options={eventTypes}
                    value={event.type}
                    clickFunction={(value) => handleEventChange('Type', value)} />
                    
                <Input name="Date (Local)" 
                    type="datetime-local" 
                    value={event.date}
                    onChange={(e) => handleEventChange('Date', e.target.value)} />

                {/* <div className='dark-card note'>Local: {formatDateString(event.date)}</div> */}
                
                <Input name="Laps" 
                    type="number" 
                    value={event.laps}
                    onChange={(e) => handleEventChange(e.target.name, e.target.value)} />

                <div className='col-2 flex-shrink-0'>
                    <Input name="Accent Color" 
                        type="color" 
                        value={event.accentColor || '#000000'}
                        onChange={(e) => handleEventChange(e.target.name, e.target.value)} />

                    <div>
                        <label>Poster</label>
                        <Button variant="icon" onClick={() => openPictureChooser('Poster URL')}>
                            <img className="poster-thumbnail" src={event.posterUrl} alt="?" />
                        </Button>
                    </div>
                </div>

                <Select 
                    name='Circuit'
                    options={circuits.map(c => c.name)}
                    value={circuits.find(c => c.circuitId === event.circuitId)?.name}
                    allowBlank={true}
                    clickFunction={(_, index) => handleEventChange('Circuit', circuits[index])} />

                {circuits !== undefined && event.circuitId !== null && (
                    <div className='dark-card note'>
                        <div className='flex gap-05 align-items-center'>
                            <div>{event.locality}, {event.country}</div>
                            <img className="flag" alt={event.locality} src={event.flagUrl} />
                            <img className="circuit-map" alt="track" src={event.trackSvgUrl} />
                        </div>
                        
                        <img className="track-map" alt="track" src={event.trackUrl} />
                    </div>
                )}
            </div>
            {/* <div>
                <Button variant="icon" onClick={() => openPictureChooser('Poster URL')}>
                    <img className="poster-thumbnail" src={event.posterUrl} alt="?" />
                </Button>
            </div> */}



            <div className="modal-footer">
                <Button variant="outline" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default EventSettings;