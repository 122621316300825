import React, { useReducer, useState, useEffect } from 'react';
import Loader from '../../../../components/Loader/Loader';
import PictureChooser from '../../../../components/PictureChooser/PictureChooser';
import AreYouSureModal from '../../../../components/AreYouSureModal/AreYouSureModal';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';
import Input from '../../../../atoms/Input/Input';
import Select from '../../../../atoms/Select/Select';

import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './DriverSettings.css';

function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload})`);   
    
    let newState = structuredClone(state);

    try {  
        switch(action.type) {
            case 'initialize':
                newState = action.payload;
                return newState;
  
            case 'reset':
                newState = {};
                return newState;
  
            case 'add':
                newState = {
                    action: 'add',
                    ...action.payload
                };
                return newState;

            case 'update':
                if(action.payload.property === 'First Name') {
                    newState.firstName = action.payload.value;
                    
                } else if(action.payload.property === 'Last Name') {
                    newState.lastName = action.payload.value;
                    
                } else if(action.payload.property === 'DOB') {
                    newState.dob = action.payload.value;
                    
                } else if(action.payload.property === 'Nationality') {
                    newState.nationality = action.payload.value;
                    newState.flagUrl = '';

                } else if(action.payload.property === 'Code') {
                    newState.code = action.payload.value;
                    
                } else if(action.payload.property === 'Driver Number') {
                    newState.driverNumber = action.payload.value;
                    
                } else if(action.payload.property === 'Profile Pic URL') {
                    newState.profilePicUrl = action.payload.value;

                } else if(action.payload.property === 'Constructor') {
                    newState.constructor = action.payload.value;
                    newState.constructorId = action.payload.value.constructorId;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function DriverSettings({config, onHide}) {
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});
    const [deleteModalConfig, setDeleteModalConfig] = useState({show: false});
    const [driver, dispatch] = useReducer(reducer, []);
    const [dbData, setDbData] = useState();
    const [hasSaved, setHasSaved] = useState(false);
    const [availableConstructors, setAvailableConstructors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [loader, setLoader] = useState({show: false, text: ''});
    
    useEffect(() => {
        if(config.show) {
			console.log(config);
            fetchDriver(config.driverId);
			fetchCountries();
        }

    }, [config]);
    
    useEffect(() => {
        if(driver !== undefined && Object.keys(driver).length > 0) {
            fetchAvailableConstructors();
        }

    }, [driver]);
    

    const fetchDriver = (driverId) => {    
		dispatch({type: 'initialize', });   //set undefined while loading

        if(driverId === undefined) {
            dispatch({type: 'add', payload: {active: config.active}});

        } else {
            let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    driverId: driverId
                })
            };
        
            setLoader({show: true, text: 'Loading Driver...'});
            fetch(apiUrl + `/app/ref/driver`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setDbData(data);
                    dispatch({type: 'initialize', payload: data});
                    setLoader({show: false});
                })
                .catch((err) => {
                    console.log(err);
                    setLoader({show: false});
                });

        }
    }
        

    const fetchAvailableConstructors = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        fetch(apiUrl + `/app/ref/constructors`, requestOptions)
            .then(response => response.json())
            .then(data => {
                let constructors = data;

                if(driver.active) {
                    constructors = constructors.filter(c => c.active !== false);
                }

                const ordered = constructors.sort((a, b) => a.name.localeCompare(b.name));
                setAvailableConstructors(ordered);
            })
            .catch((err) => {
                console.log(err);
            });
    }
        

    const fetchCountries = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        fetch(apiUrl + `/app/ref/countries`, requestOptions)
            .then(response => response.json())
            .then(data => {
				data = data.filter(c => c.nationality !== null && c.nationality.length > 0)
                const ordered = data.sort((a, b) => a.nationality.localeCompare(b.nationality));
                setCountries(ordered);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getAge = (dob) => {
        const currentDate = new Date();
        const dobDate = new Date(dob);

        let age = currentDate.getFullYear() - dobDate.getFullYear(); 
        const month = currentDate.getMonth() - dobDate.getMonth(); 

        if (month < 0 || (month === 0 && currentDate.getDate() < dobDate.getDate())) {
            age--; 
        }
        
        return age;
    }


	const save = () => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				drivers: [driver]
			})
		};

		fetch(apiUrl + "/app/ref/saveDrivers", requestOptions)
			.then(response => {
				if(response.status === 200) {
                    handleCloseEvent('Reload');
                    setHasSaved(true);
                }
			})
			.catch((err) => {
				console.log(err);
			});
	}
    

    const handlePropertyChange = (property, value) => {
        console.log(`handlePropertyChange [${property}] => ${value}`);
        
        if(property === 'Constructor') {
            value = availableConstructors.find(c => c.name === value);
        }
        dispatch({type: 'update', payload: {property: property, value: value}});
    }

    const openPictureChooser = () => {
        setPictureChooserConfig({show: true});
    }

    const closePictureChooser = (url) => {
        console.log(`url (${url.length})`, url);
        
        if(url.length > 0) {
            handlePropertyChange('Profile Pic URL', url);
        }
        
        setPictureChooserConfig({show: false});
    }

    const isDirty = () => {
        return JSON.stringify(driver) !== JSON.stringify(dbData);
    }

    const handleCloseEvent = (action) => {
        if(hasSaved) {action = 'Reload';}

        onHide(action);
        setHasSaved(false);
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true} className='gap-1'>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>
            {/* <AreYouSureModal config={deleteModalConfig} onHide={closeDeleteModal}></AreYouSureModal> */}


            <div className="flex justify-content-between">
                <h2>Driver Settings</h2>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            
            {driver !== undefined && (<>
                <div className="dark-card note">
                {`{ driverId: ${driver.driverId} }`}
                </div>
                
                <Button variant="icon" onClick={() => openPictureChooser()}>
                    <img className="answer-image small" src={driver.profilePicUrl} alt="?" />
                </Button>

				<div className='col-2'>
					<Input name="First Name" 
						type="fname" 
						value={driver.firstName}
						onChange={(e) => handlePropertyChange(e.target.name, e.target.value)} />
							
					<Input name="Last Name" 
						type="lname" 
						value={driver.lastName}
						onChange={(e) => handlePropertyChange(e.target.name, e.target.value)} />
				</div>
                    
				<div className='col-2'>
					<Select 
						name="Nationality" 
						options={countries.map(c => c.nationality)}
						value={driver.nationality}
                        allowBlank={true}
						clickFunction={(value) => handlePropertyChange('Nationality', value)} />

					<div className='dark-card note'>
						<img className='flag' src={driver.flagUrl} alt={driver.nationality} />
					</div>
				</div>
                    
				<div className='col-2'>
					<Input name="DOB"
						type="date" 
						value={driver.dob}
						onChange={(e) => handlePropertyChange(e.target.name, e.target.value)} />

					<div className='dark-card note'>Age: {getAge(driver.dob)}</div>
				</div>
                
                    
				<div className='col-2'>
					<Input name="Driver Number" 
						type="text" 
						value={driver.driverNumber}
						onChange={(e) => handlePropertyChange(e.target.name, e.target.value)} />

					<Input name="Code" 
						type="text" 
						value={driver.code}
						onChange={(e) => handlePropertyChange(e.target.name, e.target.value)} />
				</div>
                    
                    
                    
                    
				<div className='col-2'>                        
					<Select 
						name="Constructor" 
						options={availableConstructors.map(c => c.name)}
						value={driver.constructor?.name}
                        allowBlank={true}
						clickFunction={(value) => handlePropertyChange('Constructor', value)} />

					<div className='dark-card note'>
						<img className='flag' src={driver.constructor?.logoUrl} alt={driver.constructor?.name} />
					</div>
				</div>
            </>)}

            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default DriverSettings;