import React, { useState, useEffect, useRef } from 'react';
import Button from '../Button/Button.js'
import BackspaceIcon from '@mui/icons-material/Backspace';
import './Input.css';

// Usage: <Input type="text"></Input>
//      
/**
 * Input wrapper component.
 * @param {string} className - Class passthrough
 */
function Input({name, type, value, onChange, className, withClear, showLabel, ...attributes}) {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState();

    useEffect(() => {
        if(type === 'date' && value !== undefined) {
            // Datetime string with UTC timestamp
            const utcTimestamp = value;

            // Create a Date object from the UTC timestamp
            const utcDate = new Date(utcTimestamp);

            try {
                // Slice to support input (in local time)
                setInputValue(utcDate.toISOString().split('T')[0]); // YYYY-MM-DD
            } catch(e) {
                setInputValue(value);       // fallback previous value
            }

        } else if(type === 'datetime-local' && value !== undefined) {
            // Datetime string with UTC timestamp
            const utcTimestamp = value;

            // Create a Date object from the UTC timestamp
            const utcDate = new Date(utcTimestamp);

            // Convert the UTC Date object to local time
            const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));

            // Get the ISO string representation of the local time
            const localISOTime = localDate.toISOString();

            // Slice to support input (in local time)
            setInputValue(localISOTime.slice(0, 16)); // YYYY-MM-DDThh:mm
        } else {
            setInputValue(value);
        }
    }, [type, value]);

    const handleOnChange = (e) => {
        setInputValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    }
    const clearValue = () => {
        const inputElement = inputRef.current;
        const event = new Event('input', { bubbles: true, cancelable: true });

        // Set a new value to the input element
        inputElement.value = '';
        // Dispatch the event
        inputElement.dispatchEvent(event);

        handleOnChange(event);
    }

    return (
        <div className='ff-input'>
            {showLabel !== false && (<>
                {type !== 'radio' && (
                    <label for={name}>{name}</label>
                )}
            </>)}
            
            {type === 'textarea' ? (
                <textarea 
                    ref={inputRef}
                    id={name}
                    name={name}
                    value={inputValue}
                    onChange={handleOnChange}
                    {...attributes} />
            ) : (
                <input className={`${className !== undefined ? className : ''}`}
                    ref={inputRef}
                    id={name}
                    name={name}
                    type={type}
                    value={inputValue}
                    onChange={handleOnChange}
                    {...attributes} />
            )}
            
            {withClear !== undefined && (
                <Button className='clear-btn' variant='icon' onClick={clearValue}><BackspaceIcon fontSize='small' /></Button>
            )}
        </div>
    )
}

export default Input;