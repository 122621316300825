import React, { useState, useEffect } from 'react';

import Button from '../../atoms/Button/Button';
import Chip from '../../atoms/Chip/Chip';

import UserChip from '../UserChip/UserChip';
import DriverStats from '../DriverStats/DriverStats';
import SkeletonAnswer from '../Skeleton/SkeletonAnswer';
import PointsBreakdown from '../PointsBreakdown/PointsBreakdown';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import './Driver.css';

/**
 * driver===undefined : skeleton state
 * driver==={} : empty state
 * driver===driver : driver state
 */
const Driver = ({ driver, answer, eventId, variant, group, inputClick, canSubmit, isAdmin, responsesClick, submissions, showStats }) => {
    const [driverStatsConfig, setDriverStatsConfig] = useState({show: false});
    const [isExpanded, setIsExpanded] = useState(false);
    const [pointsBreakdownConfig, setPointsBreakdownConfig] = useState({show: false});

	useEffect(() => {
		if(variant==='input-toggle') {
			setIsExpanded(false);
            // console.log('driver', driver);
		}
	}, [driver]);

    const handleInputClick = () => {
        inputClick(answer, group);
        if(variant === 'input-toggle') {
            console.log('isExpanded', !isExpanded);
            setIsExpanded(!isExpanded);
        }
    }

    const openDriverStats = () => {
        console.log('openDriverStats', driver);
        setDriverStatsConfig({
            show: true, 
            driverId: driver.driverId,
            eventId: eventId
        });
    }
    const hideDriverStats = () => {
        setDriverStatsConfig({show: false});
    }

    const openPointsBreakdown = () => {
        setPointsBreakdownConfig({
            show: true,
            submissionId: answer.submissionId,
            questionId: answer.questionId,
            answerId: answer.answerId,
        })
    }
    const hidePointsBreakdown = () => {
        setPointsBreakdownConfig({show: false})
    }
    
      
  return (<>
        <PointsBreakdown config={pointsBreakdownConfig} onHide={hidePointsBreakdown}></PointsBreakdown>
        <DriverStats config={driverStatsConfig} onHide={hideDriverStats}></DriverStats>

        {driver===undefined ? (
        <SkeletonAnswer variant='driver' input={true}></SkeletonAnswer>
        ) : (
        <div variant={variant} className={`driver-container${variant === undefined ? `` : ` dark-card`}`}>
            <div className={`main${variant === undefined ? `` : ` padding-05`}`}>
                <div className="driver-details driver-answer">

                    {variant==='input-toggle' && (
                    <div>
                        {Object.keys(driver).length === 0 && ( /* driver object exists but not set (driver has not been picked) */
                            <Button variant={`icon inline square ${!isExpanded && canSubmit && 'primary'}`} onClick={handleInputClick}>
                                {isExpanded ? (<IndeterminateCheckBoxIcon />) : (<AddBoxIcon />)}
                            </Button>
                        )}

                        {Object.keys(driver).length !== 0 && ( 
                            <Button variant='icon inline square' onClick={handleInputClick}>
                                {isExpanded ? (<IndeterminateCheckBoxIcon />) : (<AddBoxIcon />)}
                            </Button>
                        )}
                    </div>
                    )}

                    {variant==='input-pick' && (
                    <div className='align-self-start flex flex-column align-items-center'>
                        {(!canSubmit && isAdmin) ? (<Button variant='link' onClick={handleInputClick}>Pick</Button>) : (
                        <label className='padding-0'>Pick</label>
                        )}
                        <Button variant='primary icon' onClick={handleInputClick} disabled={!canSubmit}><DoubleArrowIcon fontSize='small' /></Button>
                    </div>
                    )}

                    {Object.keys(driver).length !== 0 && (<>
                    <div className='flex-grow-1 name-row'>
                        <div className='single-line'>
                            <img className="answer-image small" src={driver.profilePicUrl} alt="" />
                            {driver.constructor?.logoUrl !== undefined && (
                                <img className='tiny-overlapping-logo' src={driver.constructor?.logoUrl} alt={driver.constructor?.name} />
                            )}
                        </div>

                        <div className='driver-name text-align-left single-line shrink flex-shrink-1'><b>{driver.firstName} {driver.lastName}</b></div>

                        {showStats !== false && (
                        <Button variant='icon inline outline square' onClick={openDriverStats}>
                            <InsertChartOutlinedIcon />
                        </Button>
                        )}
                        
                        {answer?.points !== undefined && (
                        <div className='flex-grow-1 flex align-items-start justify-content-end'>
                            <Button variant='icon' onClick={openPointsBreakdown}>
                                <Chip>{answer.points?.toFixed(1)}</Chip>
                            </Button>
                        </div>
                        )}
                    </div>
                    </>)}
                    
                    {(variant==='input-toggle' || variant==='input-pick') && answer?.answerId === undefined && (
                    <div>Select Driver</div>
                    )}
                </div>
                
                {answer?.points !== undefined && (
                <div className='driver-points'>
                    {answer.projectedPoints !== undefined && (
                        <div className='projected-stats'>Projected: {answer.projectedPoints.toFixed(1)}</div>
                    )}
                </div>
                )}
                

            </div>
            
                    
            {variant==='responses' && (<>
            <div className="submission-avatars" onClick={(e) => responsesClick(e, submissions)}>
                {submissions.map(user => (
                <UserChip variant='avatar' user={user}></UserChip>
                ))}
            </div>
            </>)}

        </div>
        )}
    </>);
}

export default Driver;
