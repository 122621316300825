import React, { useReducer, useState, useEffect } from 'react';
import Loader from '../../../../components/Loader/Loader';
import PictureChooser from '../../../../components/PictureChooser/PictureChooser';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';
import Input from '../../../../atoms/Input/Input';
import Select from '../../../../atoms/Select/Select';

import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './EventDriverSettings.css';


function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'delete_driver':
                if(newState[i].action === 'add') {
                    newState.splice(i, 1);
                } else {
                    newState[i].action = 'delete';
                }
                console.log('delete_driver', newState);
                return newState;

            case 'restore_driver':
                delete newState[i].action;
                return newState;

            case 'add_driver':
                newState.push({
                    action: 'add',
                    driverId: null,
                    firstName: '',
                    lastName: '',
                    profilePicUrl: '',
                    driverNumber: null,
                    code: '',
                    startPosition: '',
                    endPosition: '',
                    currentPosition: '',
                    
                    lapsCompleted: '',
                    fastestLap: '',
                    dotd: '',
                    status: null,
                    bestLapTime: 0,
                    gap: 0,
                });
                return newState;

            case 'update_driver':
                console.log(`update_driver (${action.payload.property})`, action.payload.value);
                if(action.payload.property === 'Start Position') {
                    newState[i].startPosition = action.payload.value;
                    
                } else if(action.payload.property === 'End Position') {
                    newState[i].endPosition = action.payload.value;
                    
                } else if(action.payload.property === 'Laps') {
                    newState[i].lapsCompleted = action.payload.value;

                } else if(action.payload.property === 'Status') {
                    newState[i].status = action.payload.value;

                } else if(action.payload.property === 'Fastest Lap') {
                    newState.forEach(d => d.fastestLap = false);
                    newState[i].fastestLap = true;

                } else if(action.payload.property === 'DotD') {
                    newState.forEach(d => d.dotd = false);
                    newState[i].dotd = true;

                } else if(action.payload.property === 'Driver') {
                    newState[i].driverId = action.payload.value.driverId;
                    newState[i].firstName = action.payload.value.firstName;
                    newState[i].lastName = action.payload.value.lastName;
                    newState[i].profilePicUrl = action.payload.value.profilePicUrl;
                    newState[i].driverNumber = action.payload.value.driverNumber;
                    newState[i].code = action.payload.value.code;
                    newState[i].constructor = action.payload.value.constructor;
                    newState[i].constructorId = action.payload.value.constructor.constructorId;

                } else if(action.payload.property === 'Constructor') {
                    console.log('Constructor', {...action.payload.value});
                    newState[i].constructor = {...action.payload.value};
                    newState[i].constructorId = {...action.payload.value}.constructorId;

                } else if(action.payload.property === 'Driver Number') {
                    newState[i].driverNumber = action.payload.value;

                } else if(action.payload.property === 'Profile Pic URL') {
                    newState[i].profilePicUrl = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function EventDriverSettings({config, onHide}) {
    const [drivers, dispatch] = useReducer(reducer, []);
    const [dbData, setDbData] = useState();
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [availableConstructors, setAvailableConstructors] = useState([]);
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});
    const [loader, setLoader] = useState({show: false, text: ''});
    const [statusOptions, ] = useState([null, 'DNF', 'DNS']);
    
    useEffect(() => {
        if(config.show) {
            console.log('useEffect drivers')
            fetchEventDrivers();
            fetchAvailableConstructors();
            console.log('config', config);
        }

    }, [config]);

    useEffect(() => {
        if(drivers !== undefined) {
            fetchAvailableDrivers();
        }
    }, [drivers]);
    

    const fetchEventDrivers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId
            })
        };
    
        dispatch({type: 'initialize', });   //set undefined while loading
        setLoader({show: true, text: 'Loading Event Drivers...'});
        fetch(apiUrl + `/app/ref/eventDrivers`, requestOptions)
            .then(response => response.json())
            .then(data => {
                data.forEach(d => d.action = 'none');
                setDbData(data);
                dispatch({type: 'initialize', payload: data});
                setLoader({show: false});
            })
            .catch((err) => {
                console.log(err);
                setLoader({show: false});
            });
    }
    

    const fetchAvailableDrivers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        fetch(apiUrl + `/app/ref/drivers`, requestOptions)
            .then(response => response.json())
            .then(data => {
                const activeDrivers = filterSelectableDrivers(data, data.filter(d => d.active === false), drivers); //ensure drivers previously on the event but are no longer active, are still available. Maybe should allow all drivers if the event has passed.
                const ordered = activeDrivers.sort((a, b) => a.lastName.localeCompare(b.lastName));
                setAvailableDrivers(ordered);
            
            })
            .catch((err) => {
                console.log(err);            
            });
    }
    

    const fetchAvailableConstructors = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            })
        };

        fetch(apiUrl + `/app/ref/constructors`, requestOptions)
            .then(response => response.json())
            .then(data => {
                const activeConstructors = data.filter(c => c.active !== false);
                const ordered = activeConstructors.sort((a, b) => a.name.localeCompare(b.name));
                setAvailableConstructors(ordered);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const validateInputs = (drivers) => {
        console.log('validateInputs', drivers);
        let errorMessage = '';
        let validationStatus = false;

        const missingDrivers = drivers.some(d => d.driverId === null);
        if(missingDrivers) {
            console.log('missingDrivers', missingDrivers);
            errorMessage += 'Please select a driver for all entries.\n';
            return [validationStatus, errorMessage];
        }

        const missingConstructors = drivers.some(d => d.constructorId === null);
        if(missingConstructors) {
            console.log('missingConstructors', missingConstructors);
            errorMessage += 'Please select a constructor for all entries.\n';
            return [validationStatus, errorMessage];
        }

        validationStatus = true;
        errorMessage += 'Success!\n';
        return [validationStatus, errorMessage];
    }

    const save = () => {
        const [validationStatus, errorMessage] = validateInputs(drivers);
        
        if(validationStatus === false) {
            alert(errorMessage);
            return;
        }


        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                drivers: drivers
            })
        };

        fetch(apiUrl + "/app/ref/saveEventDrivers", requestOptions)
          .then(response => {
            if(response.status === 200) {
                fetchEventDrivers();
            }
          })
        .catch((err) => {
            console.log(err);
        });
    }
    
    const formatDuration = (duration) => {        
        let minutes = Math.floor(duration / 60);
        let seconds = (duration % 60).toFixed(3); // Keep the thousandth place

        if (minutes === 0) {
            return `${seconds}`;

        } else {
            let [real, decimal] = seconds.split('.');
            real = real.padStart(2, '0'); // Ensure the real part is two digits
            seconds = `${real}.${decimal}`;
            return `${minutes}:${seconds}`;
        }
    }

    const filterSelectableDrivers = (originalArray, removalArray, keepArray) => { 
        // Create Sets of IDs for removal and keep arrays 
        const removalIds = new Set(removalArray.map(item => item.driverId)); 
        const keepIds = new Set(keepArray.map(item => item.driverId)); 
        
        return originalArray.filter(item => { 
            const inRemoval = removalIds.has(item.driverId); 
            const inKeep = keepIds.has(item.driverId); 
            
            return !inRemoval || inKeep; 
        });
    }

    const handleStatChange = (property, value, i) => {
        console.log(`handleDriversStatChange [${property}] => ${value} [${i}]`);
        
        dispatch({type: 'update_driver', payload: {index: i, property: property, value: value}});
    }

    
    const addManualDriver = () => {
        let payload = {};

        dispatch({type: 'add_driver', payload: payload});
    }

    const deleteDriver = (_, i) => {
        dispatch({type: 'delete_driver', payload: {index: i}});
    }

    const restoreDriver = (_, i) => {
        dispatch({type: 'restore_driver', payload: {index: i}});
    }

    const handleDriverChange = (driver, i) => {
        dispatch({type: 'update_driver', payload: {index: i, property: 'Driver', value: {...driver}}});
    }

    const handleConstructorChange = (constructor, i) => {
        console.log('handleConstructorChange', constructor);
        if(constructor === undefined) {
            constructor = {constructorId: null, name: '', logoUrl: ''};
        }
        dispatch({type: 'update_driver', payload: {index: i, property: 'Constructor', value: {...constructor}}});
    }

    const openPictureChooser = (index) => {
        setPictureChooserConfig({show: true, index: index});
    }

    const closePictureChooser = (url) => {
        console.log(`url (${url.length})`, url);
        
        if(url.length > 0) {
            let index = pictureChooserConfig.index;
            dispatch({type: 'update_driver', payload: {index, property: 'Profile Pic URL', value: url}});
        }
        
        setPictureChooserConfig({show: false});
    }

    const isDirty = () => {
        let cleanedDrivers = structuredClone(drivers);

        if (JSON.stringify(cleanedDrivers) !== JSON.stringify(dbData)) { 
            // console.log('cleanedDrivers', cleanedDrivers);
            // console.log('dbData', dbData);
            return true; 
        } else {
            return false;
        }
    }

    const handleCloseEvent = () => {
        dispatch({type: 'initialize', payload: []});
        setDbData();
        setAvailableDrivers([]);
        setAvailableConstructors([]);
        onHide();
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true}>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>


            <div className="flex justify-content-between">
                <h2>Driver Settings</h2>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            
            <div className="label-adorner">
            {`{ eventId: ${config.eventId}, type: ${config.type} }`}
            </div>

            <div className="divider"></div>

            <div className='overflow-auto padding-1'>
                <table className='table table-layout-fixed'>
                    <colgroup>
                        <col col-width='2em' />
                        <col col-width='long-text' />
                        <col col-width='short-number' />
                        <col col-width='long-text' />
                        <col col-width='short-number' />
                        <col col-width='short-number' />
                        <col col-width='short-number' />
                        <col col-width='short-text' />
                        {/* <col /> */}
                        {/* <col /> */}
                        <col col-width='short-text' />
                        <col col-width='2em' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Pos.</th>
                            <th>Driver</th>
                            <th>No.</th>
                            <th>Constructor</th>
                            <th>Start</th>
                            <th>End.</th>
                            <th>Laps</th>
                            <th>Status</th>
                            {/* <th>Fastest Lap</th> */}
                            {/* <th>DotD</th> */}
                            <th>Lap</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                    { drivers !== undefined && drivers.length > 0 && (
                        drivers.map((d, index) => (
                        <tr style={d.action==='delete' ? {textDecoration: 'line-through'} : (d.action==='add' ? {color: 'dodgerblue'} : {})}>
                            <td>{`${d.currentPosition!==null ? d.currentPosition : '-'}`}</td>

                            <td>
                                <div className='flex flex-column'>
                                    <div className='flex align-items-center' style={{gap: '4px'}}>
                                        <Button variant="icon" onClick={() => openPictureChooser(index)}>
                                            <img className='constructor-logo-inline' src={d.profilePicUrl} alt={d.lastName}></img>
                                        </Button>

                                        <div className='single-line flex-1' style={{overflow: 'visible'}}>
                                            <Select 
                                                options={filterSelectableDrivers(availableDrivers, drivers, [d]).map(d=>`${d.firstName} ${d.lastName}`)}
                                                value={`${d.firstName} ${d.lastName}`}
                                                allowBlank={true}
                                                clickFunction={(_, i) => handleDriverChange(filterSelectableDrivers(availableDrivers, drivers, [d])[i], index)}
                                            >
                                            </Select>
                                            </div>
                                    </div>
                                </div>
                            </td>
                            
                            <td>
                                <Input 
                                    type="text"     // text instead of number to not have the number change buttons
                                    name="Driver Number" 
                                    value={d.driverNumber}
                                    showLabel={false}
                                    onChange={(e) => handleStatChange(e.target.name, e.target.value, index)} />
                            </td>
                            
                            <td>
                                <div className='flex align-items-center' style={{gap: '4px'}}>
                                    <img className='constructor-logo-inline' src={d.constructor.logoUrl} alt={d.constructor.name}></img>
                                    
                                    <div className='single-line flex-1' style={{overflow: 'visible'}}>
                                        <Select 
                                            options={availableConstructors.map(c=>c.name)}
                                            value={d.constructor.name}
                                            allowBlank={true}
                                            clickFunction={(_, i) => handleConstructorChange(availableConstructors[i], index)}
                                        >
                                        </Select>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <Input 
                                    type="text"      // text instead of number to not have the number change buttons
                                    name="Start Position" 
                                    value={d.startPosition}
                                    showLabel={false}
                                    onChange={(e) => handleStatChange(e.target.name, e.target.value, index)} />
                            </td>
                            
                            <td>
                                <Input 
                                    type="text"       // text instead of number to not have the number change buttons
                                    name="End Position" 
                                    value={d.endPosition}
                                    showLabel={false}
                                    onChange={(e) => handleStatChange(e.target.name, e.target.value, index)} />
                            </td>
                        
                            <td>
                                <Input 
                                    type="text"        // text instead of number to not have the number change buttons
                                    name="Laps" 
                                    value={d.lapsCompleted}
                                    showLabel={false}
                                    onChange={(e) => handleStatChange(e.target.name, e.target.value, index)} />
                            </td>
                        
                            <td>
                                <Select 
                                    options={statusOptions}
                                    value={d.status}
                                    clickFunction={(status) => handleStatChange('Status', status, index)}
                                >
                                </Select>
                            </td>

                            {/* <td>
                                <Input
                                    showLabel={false}
                                    type="radio" 
                                    name={`Fastest Lap`}
                                    checked={d.fastestLap} 
                                    onChange={(e) => handleStatChange(e.target.name, true, index)} />
                            </td>

                            <td>
                                <Input
                                    showLabel={false}
                                    type="radio" 
                                    name={`DotD`}
                                    checked={d.dotd} 
                                    onChange={(e) => handleStatChange(e.target.name, true, index)} />
                            </td> */}
                            
                            <td>
                                <div className='grid'>
                                    <div>{formatDuration(d.bestLapTime)}</div>
                                    <div>+{formatDuration(d.bestLapTimeGap)}</div>
                                </div>
                            </td>

                            <td>
                                {d.action !== 'delete' && (
                                <Button variant="icon" onClick={(e) => deleteDriver(e, index)}><DeleteIcon fontSize='small' /></Button>
                                )}
                                {d.action === 'delete' && (
                                <Button variant="icon" onClick={(e) => restoreDriver(e, index)}><ReplayIcon fontSize='small' /></Button>
                                )}
                            </td>
                        
                        </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
            
            <Button variant="outline" onClick={addManualDriver}>Add Driver</Button>

            <div className="divider"></div>

            <div className="modal-footer">
                <Button variant="secondary" onClick={handleCloseEvent}>Close</Button>
            </div>

        </Modal>

     );
    
}

export default EventDriverSettings;