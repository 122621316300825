import React, { useState, useEffect, useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Select.css';

// Usage: <Dropdown variant="chip" color="primary"></Dropdown>
// Variant:
//      (undefined)
//      primary
//      accent
//      warn

function Select({name, options, value, className, allowBlank, clickFunction, showLabel}) {
    // const [showMenu, setShowMenu] = useState(false);
    const dropdownRef = useRef(null);

    // const toggleMenu = (state) => {
    //     if(state === undefined) {
    //         setShowMenu(!showMenu);
    //     } else if (state === true || state === false) {
    //         setShowMenu(state);
    //     }
    // };

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        const selectedIndex = e.target.selectedIndex - 1; //accounting for the empty option

        //return the option and index
        clickFunction(selectedValue, selectedIndex);
        // toggleMenu();
    };

    // const handleClickOutside = (event) => { 
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) { 
    //         toggleMenu(false); 
    //     } 
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => { 
    //         document.removeEventListener('mousedown', handleClickOutside); 
    //     }; 
    // }, []);

    return (
        <div className='ff-select' ref={dropdownRef}>
            {showLabel !== false && (<>
                {name !== undefined && (
                    <label for={name}>{name}</label>
                )}
            </>)}

            <select name={name} id={name} onChange={handleChange}>
                {allowBlank !== undefined && allowBlank === true && (<option value=""></option>)}
                {options.map(option => (
                <option 
                    value={option}
                    selected={option===value ? 'selected' : ''}>
                    {option}
                </option>

                ))}
            </select>
            {/* <div className='dropdown' onClick={() => toggleMenu()}>
                <div className='flex-1'>{value}</div>
                
                <ExpandMoreIcon fontSize="small"/>
            </div>
            <div className={`dropdown-menu ${showMenu ? `show` : ``}`}>
                {options.map((option, i) => (
                <div className={`dropdown-item`} onClick={() => selectOption(option, i)}>
                    {option}
                </div>

                ))}
            </div> */}
        </div>
    )
}

export default Select;