import React, { useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Chip from '../../atoms/Chip/Chip';
import './DriverStats.css';

const DriverStats = ({config, onHide}) => {
    const [driver, setDriver] = useState({});
    const [stats, setStats] = useState([]);
    
    useEffect(() => {
        if(config.show) {
            console.log('config', config);
            fetchDriver();
            fetchStats();
        }

    }, [config]);

    const fetchDriver = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                driverId: config.driverId
            })
        };

        fetch(apiUrl + `/app/ref/eventDriver`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setDriver(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchStats = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: config.eventId,
                driverId: config.driverId
            })
        };

        fetch(apiUrl + `/app/ref/driver/stats`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setStats(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }
	
	const formatPlace = (startPosition, currentPosition, status) => {
		const posChange = startPosition - currentPosition;
		let formattedPosChange;

		if (posChange > 0) { 
			formattedPosChange = `+${posChange}`; } 
		else if (posChange < 0) { 
			formattedPosChange = `${posChange}`; 
		} else { 
			formattedPosChange = '—'; 
		}

		if(status === null) {
			return (
			<div className='flex gap-05'>
				<span>P{currentPosition}</span>
				<span style={posChange===0 ? {color: 'gray'}: (posChange>0 ? {color: 'green'} : {color: 'red'})}>{formattedPosChange}</span>
			</div>
			)
		} else {
			return (<span>{status}</span>);
		}
	}

	return (
		<Modal show={config.show} onHide={onHide} closeOnOverlayClick={true}>
			<div className='dark-card flex flex-row'>
				<img className='answer-image' src={driver.profilePicUrl} alt={driver.lastName} />
				<div>
					<h2 className='single-line'>{driver.firstName} {driver.lastName}</h2>
					<p>{driver.constructor?.name}</p>
				</div>
			</div>
			
			<div className='table-container'>
				<table className='table table-layout-fixed'>
					<colgroup>
						<col col-width='icon' />
						<col col-width='icon' />
						<col col-width='max-content' />
						<col col-width='short-number' />
					</colgroup>
					<thead>
						<th></th>
						<th></th>
						<th>Race</th>
						<th>Place</th>
					</thead>
					<tbody>
						{stats.map((stat, index) => (
						<tr>
							<td>R{stat.round}</td>
							<td><img className="flag small" alt={stat.locality} src={stat.flagUrl} /></td>
							<td><div className='single-line'>{stat.name}</div></td>
							<td>{formatPlace(stat.startPosition, stat.endPosition, stat.status)}</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>

		</Modal>
	);
}

export default DriverStats;
